import React from 'react'
import Header from './Header/Header'
import './Hero.css'
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets//hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import NumberCounter from 'number-counter'
import {motion} from 'framer-motion'
const Hero = () => {
    const transition = {type: 'spring', duration : 1.5};
    const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className="hero">

        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
        {/* the best ad */}
            <div className="the-best-ad">
                <motion.div
                initial={{left:mobile? "165px":'238px'}}
                whileInView={{left:'8px'}}
                transition={{...transition, type: 'keyframes'}}
                ></motion.div>
                <span>the best fitness club in the town</span>
            </div>

        {/* Hero Header */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text '>Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal body</span>
                </div>
                <div className='span'>
                    <span>in here we  will help you to shape and build your 
                        ideal body and live up  yout life to fullest
                    </span>
                </div>
            </div>

        {/* figures */}
            <div className="figures">
                <div>
                    <span className='figures-number'>
                        <NumberCounter end={140} start ={54} delay='4' preFix="+"/>    
                    </span>
                    <span className='figures-text'>expert coaches</span>
                </div>
                <div>
                    <span className='figures-number'>
                    <NumberCounter end={978} start ={878} delay='5' preFix="+"/>    
                    </span>
                    <span className='figures-text'>members joined</span>
                </div>
                <div>
                    <span className='figures-number'>
                    <NumberCounter end={50} start ={0} delay='3' preFix="+"/>
                    </span>
                    <span className='figures-text'>fitness programs</span>
                </div>
            </div>

        {/* Hero button */}

            <div className="hero-buttons">
                <buttons className="btn btn-first">Get Start</buttons>
                <buttons className="btn btn-second">Learn More</buttons>
            </div>

        </div>
        <div className="right-h">
            <button className="btn">Join Now</button>

            <motion.div
            initial={{right:'-1rem'}}
            whileInView={{right:'5rem'}}
            transition={transition}
            className="heart-rate">
                <img src={Heart} alt="" />
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </motion.div>

            {/* hero image */}

            <img src={hero_image} alt="" className='hero-image'/>
            <motion.img 
            initial={{right:'11rem'}}
            whileInView={{right:'20rem'}}
            transition={transition}
            src={hero_image_back} alt="" className='hero-image-back'/>
            
            {/* Calories */}

            <motion.div 
                initial={{right:'37rem'}}
                whileInView={{right:'28rem'}}
                transition={transition}
                className="calories">
                <img src={Calories} alt="" />
                <div className='calories_number'>
                    <span>Calories Burned</span>
                    <span>220 kcal</span>
                </div>
            </motion.div>

        </div>
    </div>
  )
}

export default Hero 