import './App.css';
import Hero from './Components/Hero/Hero';
import Programs from './Components/Hero/Programs/Programs';
import Reasons from './Components/Reasons/Reasons'
import Plans from './Components/Plans/Plans'
import Testimonials from './assets/Testimonials/Testimonials'
import Join from './Components/Join/Join'
import Footers from './Components/Footers/Footers'

function App() {
  return (
    <div className="App">
        <Hero/>
        <Programs/>
        <Reasons/>
        <Plans/>
        <Testimonials/>
        <Join/>
        <Footers/>
    </div>
  );
}

export default App;
